import React, { useState} from 'react'
import { Button, Form } from 'react-bootstrap'
// import axios from 'axios';
import './contactMe.css'



    export function ContactMe(){

        const[inputField,setInputField] = useState({
            Name: '',
            Email: '',
            subject: '',
            Message: ''
        })
        const inputsHandler = (event) =>{
            const Evalue = event.target.value
            setInputField({ ...inputField,[event.target.name]: Evalue} )          
            }
        const FormSubed = (e) =>{
            // event.preventDefault();
            let myform = document.getElementById('FormC')
            let forminfo = new FormData(myform)
            fetch("/", {
                method: "POST",
                headers: {"Content-Type": "application/x-www-form-urlencoded"},
                body: new URLSearchParams(forminfo).toString()
            })
            .then(() =>console.log("Form Successfully submitted"))
            .catch((error) =>alert(error));
            document.getElementById('FormC').style.display = 'none'
            document.getElementById('postcontact').style.display = 'block'
            e.preventDefault();
        }
        return(
            <div className='section' id='fix'>
                <h1>Contact me</h1>
                <div id='div0'>     
                <div>
                    <h4><a id='stlyeoverride' href='mailto:jeff.mcilveen200@gmail.com'>jeff.mcilveen200@Gmail.com</a> </h4>
                    <p>I can be contacted for work at the through email.</p>
                    <p>You can also find me on these media sites.</p>
                    <a href="https://www.linkedin.com/in/jeff-mcilveen-4488b022b/" className="icon" title="LinkedIn"target={'_blank'} rel="noreferrer">
                        <svg viewBox="0 0 512 512">
                            <path d="M186.4 142.4c0 19-15.3 34.5-34.2 34.5
                             -18.9 0-34.2-15.4-34.2-34.5 0-19 15.3-34.5 34.2-34.5C171.1
                              107.9 186.4 123.4 186.4 142.4zM181.4 201.3h-57.8V388.1h57.8V201.3zM273.8
                               201.3h-55.4V388.1h55.4c0 0 0-69.3 0-98 0-26.3 12.1-41.9 35.2-41.9 21.3 0
                                31.5 15 31.5 41.9 0 26.9 0 98 0 98h57.5c0 0 0-68.2 0-118.3 0-50-28.3-74.2-68-74.2
                                 -39.6 0-56.3 30.9-56.3 30.9v-25.2H273.8z"/>
                            </svg>
                </a>
                <a href='https://github.com/Jeffrey-Mcilveen' className='github ' title='Github'target={'_blank'} rel="noreferrer">
                <svg viewBox="71 71 370 370">
      <path d="M256 70.7c-102.6 0-185.9 83.2-185.9 185.9 0 82.1 53.3
       151.8 127.1 176.4 9.3 1.7 12.3-4 12.3-8.9V389.4c-51.7 11.3-62.5-21.9-62.5-21.9
        -8.4-21.5-20.6-27.2-20.6-27.2 -16.9-11.5 1.3-11.3 1.3-11.3 18.7 1.3 28.5 19.2
         28.5 19.2 16.6 28.4 43.5 20.2 54.1 15.4 1.7-12 6.5-20.2 11.8-24.9 -41.3-4.7-84.7-20.6-84.7-91.9 0-20.3
          7.3-36.9 19.2-49.9 -1.9-4.7-8.3-23.6 1.8-49.2 0 0 15.6-5 51.1 19.1 14.8-4.1 30.7-6.2 46.5-6.3 15.8 0.1
           31.7 2.1 46.6 6.3 35.5-24 51.1-19.1 51.1-19.1 10.1 25.6 3.8 44.5 1.8 49.2 11.9 13 19.1 29.6 19.1 49.9
            0 71.4-43.5 87.1-84.9 91.7 6.7 5.8 12.8 17.1 12.8 34.4 0 24.9 0 44.9 0 51 0 4.9 3 10.7 12.4 8.9 73.8-24.6
             127-94.3 127-176.4C441.9 153.9 358.6 70.7 256 70.7z"/>
      </svg>
                </a>
                <a href="https://www.facebook.com/jeff.mcilveen.77/" className="faceB" title="Facebook"target={'_blank'} rel="noreferrer">
                    <svg viewBox="0 0 512 512">
                        <path d="M211.9 197.4h-36.7v59.9h36.7V433.1h70.5V256.5h49.2l5.2-59.1h-54.4c0
                        0 0-22.1 0-33.7 0-13.9 2.8-19.5 16.3-19.5 10.9 0 38.2 0 38.2 0V82.9c0 0-40.2 0-48.8 0 -52.5 0-76.1 
                        23.1-76.1 67.3C211.9 188.8 211.9 197.4 211.9 197.4z"/>
                        </svg>
                        </a>
                </div>
                </div>

                <div className='contact'>
                <div id='postcontact'>
                    <h2>Thank you for the E-mail {inputField.Name}.<br/> I'll get back to you shortly.</h2>
                </div>
                <Form  id='FormC' action='' name='contactform' method='POST' value='' onSubmit={FormSubed}
                data-netlify-honeypot="bot-field"
                >
                 <input hidden name='form-name' value='contactform' />
                 <div hidden>
                    <input name='bot-field'/>
                 </div>
                    <Form.Label>Your Name</Form.Label>
                    <Form.Control type='text' name='Name' onChange={inputsHandler} value={inputField.Name}  placeholder="Your Name"/>

                    <Form.Label>Your Email Address</Form.Label>
                    <Form.Control type='email' name='Email' onChange={inputsHandler} value={inputField.Email}  placeholder="E-mail address"/>

                    <Form.Label>Subject</Form.Label>
                    <Form.Control type='text' name='subject' onChange={inputsHandler} value={inputField.subject}  placeholder="Subject"/>
                    
                    <Form.Label> Message </Form.Label><br/>
                    <textarea id='texta' placeholder='Your Message' name='Message' onChange={inputsHandler} value={inputField.Message}></textarea><br/><br/>

                    <Button type='submit' onClick={FormSubed} >Send Email</Button>
                </Form>
                </div>
                <p id='copyr'>© 2022 Jeffrey Mcilveen <br/>all rights reserved</p>
            </div>


        )

    }