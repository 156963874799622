import React, {Component} from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
// import GBCpic from '../Images/George_Brown_College_logo.png'
import { motion } from 'framer-motion'
// import '../App.css';
import './about.css'

export default class About extends Component{
    render(){
        return(
            <div className='section' id='fix'>
                 <h1 className='aboutHclass'>About me</h1>
                 <h5>and what can I do for you</h5>
                 <Container id='aboutClassContainer'>
                 <Row>
                    <Col>
                    <motion.div
                        whileHover={{
                            scale:1.1,
                            transition:{
                                duration: 0.2,
                                delay: 0.0}
                        }}
                        initial={{opacity:0,translateX: -100}}
                        whileInView={{opacity:1,translateX: 0, transition: {duration: .3,delay: .4} }}
                        // transition={{duration: .3,delay: .4}}
                        >
                    <Card border='light' className='aboutCardclass' id='ida1' >
                        
                        <Card.Title>Graduated from George Brown College</Card.Title>
                        {/* <img src={GBCpic} className='aboutpics'></img> */}
                        <Card.Text>I’ve recently graduated from the Computer Programmer Analyst program.
                        My technical strengths are in coding, testing, and understanding
                        software programs and applications.</Card.Text>
                        </Card></motion.div>
                        </Col>
                    <Col><motion.div
                        whileHover={{
                            scale:1.1,
                            transition:{
                                duration: 0.2,
                                delay: 0.0}
                        }}
                        initial={{opacity:0,translateX: 100}}
                        whileInView={{opacity:1,translateX: 0,transition:{duration: .3,delay: .8}}}
                        >
                    <Card border='light' className='aboutCardclass' id='ida1'>
                            <Card.Title>Personal Philosophy</Card.Title>
                            <Card.Text>As a programmer , my personal philosophy is that you never stop learning.
                                 As new languages and frameworks are developed, it’s important to learn as much
                                  as you can about them. I am diligent, patient, and knowledge seeking.
                                 </Card.Text>
                                  </Card></motion.div>
                                  </Col>
                    </Row>
                    <Row>
                        <Col>
                        <motion.div
                        whileHover={{
                            scale:1.1,
                            transition:{
                            duration: 0.2,
                            delay: 0.0}
                        }}
                            initial={{opacity:0,translateX: -100}}
                            whileInView={{opacity:1,translateX: 0,transition:{duration: .3,delay: 1.2}}}>
                        <Card border='light' className='aboutCardclass' id='ida1'>
                            <Card.Title>Web Development</Card.Title>
                                      <Card.Text>
                                        I am proficient in the basic JavaScript and Html5\CSS3, and have solid experience in PHP,
                                        MongoDB, SQL, Node.js, React.js, GraphQL, Angular.js and Typescript. I have built multiple
                                        websites and web apps that incorporate both frontend and backend systems.
                                        I can serve as a frontend developer, backend developer or even a full stack developer.
                                        </Card.Text>
                                        </Card>
                                        </motion.div>
                                        </Col>
                        <Col>
                        <motion.div
                        whileHover={{
                        scale:1.1,
                        transition:{
                        duration: 0.2,
                        delay: 0.0}
                        }}
                        initial={{opacity:0,translateX: 100}}
                        whileInView={{opacity:1,translateX: 0,transition:{duration: .3,delay: 1.7}}}>                                                          
                        <Card border='light' className='aboutCardclass' id='ida1'>
                            <Card.Title>Software Development</Card.Title>
                            <Card.Text>
                            I'm skilled in in C# ASP.net, Java, Python and SQL/SQLite.
                            I'm also high skilled data structures and algorithms, i know how to make an app perform at top speed.
                            I've built a few apps using these languages, you'll be able to see them in my work section.

                            </Card.Text>
                                    </Card></motion.div>  
                                    </Col>
                        </Row>
                 </Container>
                 <p id='copyr'>© 2022 Jeffrey Mcilveen <br/>all rights reserved</p>
            </div>
        )
    }
}