import React, {Component} from 'react'
import { Nav } from 'react-bootstrap'
import { Navbar } from 'react-bootstrap'
import Container from 'react-bootstrap/Container';
// import '../App.css';

export default class NaviBar extends Component{
    render(){
        return(
            <div>
                <Navbar bg='dark' variant='dark' fixed='top' className='txt'>
                <Container>
                <Navbar.Brand>Jeffrey Mcilveen</Navbar.Brand>
                
                    <Nav.Link href='#home' >Home</Nav.Link>
                    <Nav.Link href='#about'>About</Nav.Link>
                    <Nav.Link href='#skills'>Skills</Nav.Link>
                    <Nav.Link href='#work'>Work</Nav.Link>
                    <Nav.Link href='#contact'>Contact</Nav.Link>
                    </Container>
                </Navbar>
                
               
            </div>
        )
    }
}