import React, {Component} from 'react'
import { Figure } from 'react-bootstrap'
// import { motion } from 'framer-motion'
import HTMLF from '../Images/HTMLLogo.png'
import CSSP from '../Images/CSSLogo.png'
import JSP from '../Images/JSIMG.png'
import Csharp from '../Images/cSharp2.png'
import RJS from '../Images/RJSLogo.png'
import Ang from '../Images/anguarJS.png'
import NodeP from '../Images/NodePic.png'
import Graph from '../Images/GraphQL.png'
import Mongo from '../Images/Mongo.png'
import SQL from '../Images/SQLpic.png'
import Python from '../Images/pythonPic.png'
import './skills.css';

export default class Skills extends Component{
    render(){
        return(
            <div className='section' id='fix'>
                
                <h3>ToolKit</h3>
                <p style={{}}>Listed below are the tools I am proficient in. My skillset allows me
                     to create API's that work efficiently and cleanly.<br/> 
                </p>
                <div className='skillgroup'>
                <Figure>
                    <Figure.Image className='imgclassS'src={HTMLF} roundedCircle/>
                    <Figure.Caption>
                        <p>HTML 5</p>
                    </Figure.Caption>
                </Figure>
                <Figure>
                    <Figure.Image className='imgclassS'src={CSSP} roundedCircle/>
                    <Figure.Caption>
                        <p>CSS </p>
                    </Figure.Caption>
                </Figure>
                <Figure>
                    <Figure.Image className='imgclassS'src={JSP} roundedCircle/>
                    <Figure.Caption>
                        <p>JavaScript</p>
                    </Figure.Caption>
                </Figure>
                <Figure>
                    <Figure.Image className='imgclassS'src={Csharp} roundedCircle/>
                    <Figure.Caption>
                        <p>C Sharp</p>
                    </Figure.Caption>
                </Figure>
                <Figure>
                    <Figure.Image className='imgclassS2'src={Python} roundedCircle/>
                    <Figure.Caption>
                        <p>Python</p>
                    </Figure.Caption>
                </Figure>
                <Figure>
                    <Figure.Image className='imgclassS'src={RJS} roundedCircle/>
                    <Figure.Caption>
                        <p>React.js</p>
                    </Figure.Caption>
                </Figure>
                <Figure>
                    <Figure.Image className='imgclassS'src={Ang} roundedCircle/>
                    <Figure.Caption>
                        <p>Angular JS</p>
                    </Figure.Caption>
                </Figure><br/>
                <Figure>
                    <Figure.Image className='imgclassS'src={NodeP} roundedCircle/>
                    <Figure.Caption>
                        <p>Node js </p>
                    </Figure.Caption>
                </Figure>
                <Figure>
                    <Figure.Image className='imgclassS'src={Graph} roundedCircle/>
                    <Figure.Caption>
                        <p>GraphQL</p>
                        
                    </Figure.Caption>
                </Figure>
                <Figure>
                    <Figure.Image className='imgclassS'src={Mongo} roundedCircle/>
                    <Figure.Caption>
                        <p>Mongo DB</p>
                        
                    </Figure.Caption>
                </Figure>
                <Figure >
                    <Figure.Image className='imgclassS' src={SQL} roundedCircle />
                    <Figure.Caption>
                        <p>SQL</p>
                        
                    </Figure.Caption>
                </Figure>
                </div>
                
                
                
                
                    {/* <div className='motionstyle'>
                    <motion.div
                    initial={{opacity:0,translateY: 100}}
                    whileInView={{opacity:1,translateY: 0}}
                    transition={{duration: .3,delay: .2}}
                    >
                    <h3>Skills</h3>
                    </motion.div>
                    </div> */}
                    <p id='copyr'>© 2022 Jeffrey Mcilveen <br/>all rights reserved</p>
            </div>
        )
    }
}