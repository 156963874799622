import React, {Component} from 'react'
import myphoto2 from "../Images/myphoto2.jpg"
import { motion } from 'framer-motion'
// import RJS from '../Images/RJSLogo.png'
import './home.css'


export default class Home extends Component{
    render(){
        return(
            <div className='section' id='fix'>
                <h1 id='present'>Jeffrey McIlveen</h1>
                {/* <h3>Developer deployment - not a finished site</h3> */}
                <p>pronouced Mac-L-veen</p>
               <div id='fullHome'>
               <div id='voiddiv'>
                <p>Hello, I’m Jeffrey McIlveen, entry level web and software developer. Welcome to my site.
                     I am currently looking for internships, entry level positions and freelance work. If you
                      need it, I can build it. Feel free to contact
                     me through email using the form below. If you
                      contact me I will get back to you within one business day.</p>
                      <p style={{marginTop: '30px'}}>I currently specialize in website development (frontend and backend) as well as mobile app and software development. </p>
                </div>
                <img src= {myphoto2} width={380} height={500} className='aboutpic'  alt="Mypic"/>
            <div className='homediv'>
                <br/>
                <motion.div id='divmotion1'
                    initial={{opacity:0,translateX: 200}}
                    whileInView={{opacity:1,translateX: 0, translateY:0, transition: {duration: .3,delay: .4} }}
                >
                    {/* <img className='imgclassS'src={RJS} alt="Mypic"></img> */}
                    <p id='phome1'>Entry level Web Developer/<br/> Mobile Developer</p>
                </motion.div>
                
                
                
                <motion.p id='phome2'
                initial={{opacity:0,translateX: 200}}
                whileInView={{opacity:1,translateX: 0, translateY:0, transition: {duration: .3,delay: 1.1} }}
                >IT Specialist</motion.p>
                {/* <p>Entry level Software Engineer</p> */}
                <motion.p id='phome3'
                initial={{opacity:0,translateX: 200}}
                whileInView={{opacity:1,translateX: 0, translateY:0, transition: {duration: .3,delay: 1.8} }}>
                    Freelancer</motion.p>
                </div>
                </div>
                <p id='copyr'>© 2022 Jeffrey Mcilveen <br/>all rights reserved</p>
            </div>
        )
    }
}