// import './App.css';
//import ReactFullpage from "@fullpage/react-fullpage";
import Home from './components/home';
import About from './components/about';
import Skills from './components/skills';
import NaviBar from "./components/Navibar";
import Work from "./components/work";
import { ContactMe } from "./components/ContactMe";
import ReactFullpage from "@fullpage/react-fullpage";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';


const anchors = ["home","about","skills","work","contact"]
function App() {
  return (
    <div className="App">
      
      <NaviBar/>
      <ReactFullpage
    anchors={anchors}
    licenseKey= 'E1CKK-F2SJJ-1K807-K5J38-QNQSO'
    navigation
    navigationTooltips={anchors}
    sectionsColor={["#121212","#282c34","#282c34","#282c34","#282c34"]}
    onLeave={(origin, destination, direction) => {
      // console.log("onLeave event", { origin, destination, direction });
    }}
    render={({ state, fullpageApi }) => {
      // console.log("render prop change", state, fullpageApi);

      return (
        <div>
          
          <Home/>
          <About/>
          <Skills/>
          <Work/>
          <ContactMe/>
          
        </div>
      );
    }}
  />
    </div>
  );
}

export default App;
