import React, {Component} from 'react'
import { Card } from 'react-bootstrap'
import { CardGroup } from 'react-bootstrap'
import MERN_pic from '../Images/MERN_pic.jpg'
import Weather_pic2 from '../Images/Weather.jpg'
import MEAN_pic from '../Images/MEAN_pic.jpg'
import Csharp from '../Images/Csharp_pic.jpg'
import { motion } from 'framer-motion'
import './work.css';

export default class Work extends Component{

        
    render(){
        return(
            <div className='section' id='fix'>
                <div className='motionstyle2'>
                <h1>Work Portfolio</h1>
                    <CardGroup>
                        <motion.div
                            initial={{opacity:0,translateX: 100}}
                        whileInView={{opacity:1,translateX: 0, transition: {duration: .3,delay: .4} }}>
                            <Card border='dark'  className='Cardclass2' id='outliner'>
                                
                                <div className='CardImage'>
                                    <Card.Img variant='top' src={MERN_pic}></Card.Img>
                                    <Card.ImgOverlay><div style={{paddingTop: "25px"}} >
                                    <a href='https://github.com/Jeffrey-Mcilveen/React_Project' className='github' target={'_blank'} rel="noreferrer">
                <svg viewBox="71 71 370 370">
      <path d="M256 70.7c-102.6 0-185.9 83.2-185.9 185.9 0 82.1 53.3 151.8 127.1 176.4 9.3 1.7 12.3-4 12.3-8.9V389.4c-51.7 11.3-62.5-21.9-62.5-21.9 -8.4-21.5-20.6-27.2-20.6-27.2 -16.9-11.5 1.3-11.3 1.3-11.3 18.7 1.3 28.5 19.2 28.5 19.2 16.6 28.4 43.5 20.2 54.1 15.4 1.7-12 6.5-20.2 11.8-24.9 -41.3-4.7-84.7-20.6-84.7-91.9 0-20.3 7.3-36.9 19.2-49.9 -1.9-4.7-8.3-23.6 1.8-49.2 0 0 15.6-5 51.1 19.1 14.8-4.1 30.7-6.2 46.5-6.3 15.8 0.1 31.7 2.1 46.6 6.3 35.5-24 51.1-19.1 51.1-19.1 10.1 25.6 3.8 44.5 1.8 49.2 11.9 13 19.1 29.6 19.1 49.9 0 71.4-43.5 87.1-84.9 91.7 6.7 5.8 12.8 17.1 12.8 34.4 0 24.9 0 44.9 0 51 0 4.9 3 10.7 12.4 8.9 73.8-24.6 127-94.3 127-176.4C441.9 153.9 358.6 70.7 256 70.7z"/>
      </svg>
                </a><a href= 'https://drive.google.com/file/d/1_j82AIzD5_xagGz4akYn8biN5AP9XFZP/view?usp=share_link' target={'_blank'} rel="noreferrer" className='eyeball' >
                <svg viewBox="0 0 22 22"><g transform="matrix(.02146 0 0 .02146 1 1)" ><path d="m466.07 161.53c-205.6 0-382.8 121.2-464.2 296.1-2.5 5.3-2.5 11.5 0 16.9 81.4 174.9 258.6 296.1 464.2 296.1 205.6 0 382.8-121.2 464.2-296.1 2.5-5.3 2.5-11.5 0-16.9-81.4-174.9-258.6-296.1-464.2-296.1m0 514.7c-116.1 0-210.1-94.1-210.1-210.1 0-116.1 94.1-210.1 210.1-210.1 116.1 0 210.1 94.1 210.1 210.1 0 116-94.1 210.1-210.1 210.1"/><circle cx="466.08" cy="466.02" r="134.5"/></g></svg>
                </a></div>
                                    </Card.ImgOverlay></div>
                        <Card.Title>MERN Stack project</Card.Title>
                        
                        <Card.Text>A web app made for a school project using the Mern Stack.
                             This project displays a high level of skill in front and backend principles,
                             performing CRUD operations through the use of Rest API's. Styled with Bootstrap
                             
                             </Card.Text>
                             
                    </Card>
                    </motion.div>
                    <motion.div
                            initial={{opacity:0,translateX: 100}}
                        whileInView={{opacity:1,translateX: 0, transition: {duration: .3,delay: 0.8} }}>
                    <Card border='dark'  className='Cardclass2'id='outliner2'>
                    <div className='CardImage'>
                                    <Card.Img variant='top' src={Weather_pic2}></Card.Img>
                                    <Card.ImgOverlay><div style={{paddingTop: "25px"}} >
                                    <a href='https://github.com/Echohead200/Moblie_project_Jeff' target={'_blank'} rel="noreferrer" className='github'>
                <svg viewBox="71 71 370 370">
      <path d="M256 70.7c-102.6 0-185.9 83.2-185.9 185.9 0 82.1 53.3 151.8 127.1 176.4 9.3 1.7 12.3-4 12.3-8.9V389.4c-51.7 11.3-62.5-21.9-62.5-21.9 -8.4-21.5-20.6-27.2-20.6-27.2 -16.9-11.5 1.3-11.3 1.3-11.3 18.7 1.3 28.5 19.2 28.5 19.2 16.6 28.4 43.5 20.2 54.1 15.4 1.7-12 6.5-20.2 11.8-24.9 -41.3-4.7-84.7-20.6-84.7-91.9 0-20.3 7.3-36.9 19.2-49.9 -1.9-4.7-8.3-23.6 1.8-49.2 0 0 15.6-5 51.1 19.1 14.8-4.1 30.7-6.2 46.5-6.3 15.8 0.1 31.7 2.1 46.6 6.3 35.5-24 51.1-19.1 51.1-19.1 10.1 25.6 3.8 44.5 1.8 49.2 11.9 13 19.1 29.6 19.1 49.9 0 71.4-43.5 87.1-84.9 91.7 6.7 5.8 12.8 17.1 12.8 34.4 0 24.9 0 44.9 0 51 0 4.9 3 10.7 12.4 8.9 73.8-24.6 127-94.3 127-176.4C441.9 153.9 358.6 70.7 256 70.7z"/>
      </svg>
                </a><a href= 'https://drive.google.com/file/d/1YTH4giP2__i9-7zgg_e2X9__0Khwl6x0/view?usp=share_link' target={'_blank'} rel="noreferrer" className='eyeball' >
                <svg viewBox="0 0 22 22"><g transform="matrix(.02146 0 0 .02146 1 1)" ><path d="m466.07 161.53c-205.6 0-382.8 121.2-464.2 296.1-2.5 5.3-2.5 11.5 0 16.9 81.4 174.9 258.6 296.1 464.2 296.1 205.6 0 382.8-121.2 464.2-296.1 2.5-5.3 2.5-11.5 0-16.9-81.4-174.9-258.6-296.1-464.2-296.1m0 514.7c-116.1 0-210.1-94.1-210.1-210.1 0-116.1 94.1-210.1 210.1-210.1 116.1 0 210.1 94.1 210.1 210.1 0 116-94.1 210.1-210.1 210.1"/><circle cx="466.08" cy="466.02" r="134.5"/></g></svg>
                </a></div>
                                    </Card.ImgOverlay></div>
        
                        <Card.Title>Weather App project </Card.Title>
                        <Card.Text>A single page Android app project made in React Native that accesses a weather service API and 
                            returns the next 7 days of forecasts. Display's knowledge of Rest API's and moblie design.</Card.Text>
                    </Card>
                    </motion.div>
                    <motion.div
                            initial={{opacity:0,translateX: 100}}
                        whileInView={{opacity:1,translateX: 0, transition: {duration: .3,delay: 1.2} }}>
                    <Card border='dark' className='Cardclass2' id='outliner2'>
                    <div className='CardImage'>
                    <Card.Img variant='top' src={MEAN_pic} ></Card.Img>
                    
                        <Card.ImgOverlay><div style={{paddingTop: "25px"}} >
                        <a  href='https://github.com/Jeffrey-Mcilveen/Angular-GraphQl-Project'target={'_blank'} rel="noreferrer" className='github'>
                        <svg viewBox="71 71 370 370">
                            <path d="M256 70.7c-102.6 0-185.9 83.2-185.9 185.9 0 82.1 53.3 151.8 127.1 176.4 9.3 1.7 12.3-4 12.3-8.9V389.4c-51.7 11.3-62.5-21.9-62.5-21.9 -8.4-21.5-20.6-27.2-20.6-27.2 -16.9-11.5 1.3-11.3 1.3-11.3 18.7 1.3 28.5 19.2 28.5 19.2 16.6 28.4 43.5 20.2 54.1 15.4 1.7-12 6.5-20.2 11.8-24.9 -41.3-4.7-84.7-20.6-84.7-91.9 0-20.3 7.3-36.9 19.2-49.9 -1.9-4.7-8.3-23.6 1.8-49.2 0 0 15.6-5 51.1 19.1 14.8-4.1 30.7-6.2 46.5-6.3 15.8 0.1 31.7 2.1 46.6 6.3 35.5-24 51.1-19.1 51.1-19.1 10.1 25.6 3.8 44.5 1.8 49.2 11.9 13 19.1 29.6 19.1 49.9 0 71.4-43.5 87.1-84.9 91.7 6.7 5.8 12.8 17.1 12.8 34.4 0 24.9 0 44.9 0 51 0 4.9 3 10.7 12.4 8.9 73.8-24.6 127-94.3 127-176.4C441.9 153.9 358.6 70.7 256 70.7z"/>
                            </svg>
                        </a>
                        <a href= 'https://drive.google.com/file/d/1uSEcbsASJqwIhtkzIZPVOwGM8rTG5i-Q/view?usp=share_link' target={'_blank'} rel="noreferrer" className='eyeball' >
                <svg viewBox="0 0 22 22"><g transform="matrix(.02146 0 0 .02146 1 1)" ><path d="m466.07 161.53c-205.6 0-382.8 121.2-464.2 296.1-2.5 5.3-2.5 11.5 0 16.9 81.4 174.9 258.6 296.1 464.2 296.1 205.6 0 382.8-121.2 464.2-296.1 2.5-5.3 2.5-11.5 0-16.9-81.4-174.9-258.6-296.1-464.2-296.1m0 514.7c-116.1 0-210.1-94.1-210.1-210.1 0-116.1 94.1-210.1 210.1-210.1 116.1 0 210.1 94.1 210.1 210.1 0 116-94.1 210.1-210.1 210.1"/><circle cx="466.08" cy="466.02" r="134.5"/></g></svg>
                </a>
                </div>
                        </Card.ImgOverlay></div>
                    
                        <Card.Title><p>Mean Stack project </p></Card.Title>
                        
                        <Card.Text>
                            <p>
                                A short-term rental app project designed with the MEAN Stack.
                                Uses GraphQL to perform database queries.
                                Also integrates Angular Materials for styling.
                            </p> 
                        </Card.Text>
                    </Card>
                    </motion.div>
                    
                    <motion.div
                            initial={{opacity:0,translateX: 100}}
                        whileInView={{opacity:1,translateX: 0, transition: {duration: .3,delay: 1.7} }}>
                    <Card border='dark' className='Cardclass2' id='outliner3'>

                    
                    <div className='CardImage'>
                    <Card.Img variant='top' src={Csharp}></Card.Img>
                                    <Card.ImgOverlay><div style={{paddingTop: "25px"}}>
                                    <a href='https://github.com/Jeffrey-Mcilveen/Airline_project' target={'_blank'} rel="noreferrer" className='github'>
                <svg viewBox="71 71 370 370">
      <path d="M256 70.7c-102.6 0-185.9 83.2-185.9 185.9 0 82.1 53.3 151.8 127.1 176.4 9.3 1.7 12.3-4 12.3-8.9V389.4c-51.7 11.3-62.5-21.9-62.5-21.9 -8.4-21.5-20.6-27.2-20.6-27.2 -16.9-11.5 1.3-11.3 1.3-11.3 18.7 1.3 28.5 19.2 28.5 19.2 16.6 28.4 43.5 20.2 54.1 15.4 1.7-12 6.5-20.2 11.8-24.9 -41.3-4.7-84.7-20.6-84.7-91.9 0-20.3 7.3-36.9 19.2-49.9 -1.9-4.7-8.3-23.6 1.8-49.2 0 0 15.6-5 51.1 19.1 14.8-4.1 30.7-6.2 46.5-6.3 15.8 0.1 31.7 2.1 46.6 6.3 35.5-24 51.1-19.1 51.1-19.1 10.1 25.6 3.8 44.5 1.8 49.2 11.9 13 19.1 29.6 19.1 49.9 0 71.4-43.5 87.1-84.9 91.7 6.7 5.8 12.8 17.1 12.8 34.4 0 24.9 0 44.9 0 51 0 4.9 3 10.7 12.4 8.9 73.8-24.6 127-94.3 127-176.4C441.9 153.9 358.6 70.7 256 70.7z"/>
      </svg></a><a href= 'https://drive.google.com/file/d/1FnaGcg8_Nhis8k0gDlrsHup5-qviuKQ7/view?usp=share_link' target={'_blank'} rel="noreferrer" className='eyeball' >
                <svg viewBox="0 0 22 22"><g transform="matrix(.02146 0 0 .02146 1 1)" ><path d="m466.07 161.53c-205.6 0-382.8 121.2-464.2 296.1-2.5 5.3-2.5 11.5 0 16.9 81.4 174.9 258.6 296.1 464.2 296.1 205.6 0 382.8-121.2 464.2-296.1 2.5-5.3 2.5-11.5 0-16.9-81.4-174.9-258.6-296.1-464.2-296.1m0 514.7c-116.1 0-210.1-94.1-210.1-210.1 0-116.1 94.1-210.1 210.1-210.1 116.1 0 210.1 94.1 210.1 210.1 0 116-94.1 210.1-210.1 210.1"/><circle cx="466.08" cy="466.02" r="134.5"/></g></svg>
                </a></div>
                                    </Card.ImgOverlay></div>
                        <Card.Title> C# project </Card.Title>
                        <Card.Text>
                           A project that uses C# to build a airplane management system.
                                Performs booking operations and
                                displays knowledge of OPP principles.
                        </Card.Text>
                        
                    </Card>
                    </motion.div>
                    </CardGroup>
                    <br/><br/>
                    <p id='copyr'>© 2022 Jeffrey Mcilveen <br/>all rights reserved</p>
                    </div>
                    
            </div>
        )
    }
}